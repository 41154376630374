import React from "react"
import styles from "./Footer.module.scss"
import InputMask from "react-input-mask"

const Footer = () => {
  return (
    <>
      <footer className={styles.footerForm}>
        <div className={styles.form}>
          <h1>Let’s get in touch!</h1>
          <div className={styles.inputGroup}>
            <input type="text" className={styles.inputText} placeholder="First Name"/>
            <input type="text" className={styles.inputText} placeholder="Last Name"/>
          </div>
          <div className={styles.inputGroup}>
            <input type="text" className={styles.inputText} placeholder="E-mail"/>
            <InputMask className={styles.inputText} placeholder="Phone number" mask="\+99 999 999 999" maskChar="_" />
          </div>
          <textarea className={styles.inputTextArea} cols="30" rows="10"/>
          <div className={styles.send}>
            <input type="submit" value="Submit" />
          </div>
        </div>
      </footer>
      <footer className={styles.footer}>
        <span>Made with <span aria-label="heart" role="img" >❤️</span> by <a href="https://jakubgrzywacz.pl/">Jakub Grzywacz</a> &nbsp; / &nbsp; {new Date().getFullYear()}</span>
      </footer>
    </>
  )
};

export default Footer;