import React from "react"
import Layout from "../components/layout"
import Navigation from "../components/Navigation/Navigation"
import Header from "../components/Header/Header"
import Informations from "../components/Informations/Informations"
import Pricing from "../components/Pricing/Pricing"
import Footer from "../components/Footer/Footer"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title={"KEEP IT - HOME PAGE"} />
    <Navigation/>
    <Header/>
    <Informations/>
    <Pricing/>
    <Footer/>
  </Layout>
)

export default IndexPage
