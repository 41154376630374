import React from "react"
import styles from "./Header.module.scss"

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.text}>
        <h1>Write notes everywhere</h1>
        <p>Compact application for creating quick notes</p>
      </div>
      <div className={styles.callToAction}>
        <a href="#">Sign In</a>
      </div>
    </header>
  )
};

export default Header;