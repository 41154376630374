import React from "react"
import styles from "./Pricing.module.scss"
import Card from "./Card"

const plans = [
  {
    name: "Monthly",
    description: "Pay monthly to get access to creating new notes!",
    price: "2$",
    special: false,
  },
  {
    name: "Annually",
    description: "Pay annually to get access to creating new notes!",
    price: "20$",
    special: true,
  },
  {
    name: "Enterprise",
    description: "Contact with us to to determine more details.",
    price: "??$",
    special: false,
  },
]

const Pricing = () => {
  return (
    <section className={styles.pricing}>
      <div className={styles.text}>
        <h3>Pricing</h3>
        <p>You can try KEEPIT <strong>for free</strong> for 3months</p>
        <p>or you can select one of plans below.</p>
      </div>
      <div className={styles.cards}>
        {plans.map(({name, description, price, special}) => (
          <Card
            name={name}
            description={description}
            price={price}
            special={special}
          />
          )
        )}
      </div>
    </section>
  )
};

export default Pricing;