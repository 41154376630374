import React from "react"
import styles from "./Navigation.module.scss"

const Navigation = () => {
  return (
    <header className={styles.header}>
      <h6 className={styles.title}>
        Keep IT
      </h6>
      <nav className={styles.navigation}>
        <ul>
          <li><a href="#">Features</a></li>
          <li><a href="#">Pricing</a></li>
          <li><a href="#">Blog</a></li>
          <li><a href="#">FAQ</a></li>
          <li className={styles.special}><a href="#">Login</a></li>
        </ul>
      </nav>
    </header>
  )
};

export default Navigation;