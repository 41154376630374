import React from "react"
import styles from "./Pricing.module.scss"

const Card = ({name, description, price, special}) => {
  return (
    <div className={`${styles.card} ${special && styles.special}`}>
      <h4>{name}</h4>
      <p>{description}</p>
      <span>{price}</span>
    </div>
  )
}

export default Card;