import React from "react"
import styles from "./Informations.module.scss"
import image from "../../images/phone-image.png"

const Informations = () => {
  return (
    <section className={styles.info}>
      <div className={styles.text}>
        <h3>Use it everywhere</h3>
        <p><strong>KeepIt</strong> works in browser, so you can write notes <b>anywhere you are</b></p>
        <div className={styles.callToAction}>
          <a href="#">Start using</a>
        </div>
      </div>
      <img src={image} alt="phones images" className={styles.image}/>
    </section>
  )
};

export default Informations;